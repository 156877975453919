<template>
  <!-- 付款单弹窗 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    title="付款单信息"
    class="dialog"
  >
    <div class="addProcess">
      <base-form
        :componentList="addForm"
        :formAttrs="{
          model: queryParas,
          labelWidth: '140px',
        }"
        class="formStyle"
        ref="addFormData"
        @handleFilter="handleFilter"
        @clearParams="resetFrom"
      >
      </base-form>
      <!-- 表格 -->
      <base-table
          :columns="paymentColumns"
          :showPage="true"
          :tableAttrs="{
            data: tableData,
            stripe: true,
          }"
          :queryParas="queryParas"
          :loadCount="loadCount"
          :api="api"
          :getApi="'getPageList'"
          :webPage="false"
          :dataSource.sync="tableData"
          @selection-change="changeDatas"
          @select='select'
          @pageIndexChange="pageIndexChange"
          @pageSizeChange="pageSizeChange"
          ref="tableData"
          :selfChangeHeight="200"

        >
          <template slot="index" slot-scope="scope">
            {{scope.$index + 1}}
          </template>
          <template slot="action" slot-scope="scope" >
            <icon-button
              content="查看"
              icon="iconfont iconchakan"
              @click="info(scope.row)"
            />
          </template>
        </base-table>
    </div>
    <template slot="footer">
      <base-button label="提 交" @click="submit"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false;"
      ></base-button>
    </template>
    <!-- 付款单详情信息 -->
    <payment-order-details-dialog :visible.sync="visibleOrderDetails" v-if="visibleOrderDetails" :keyId="keyId"></payment-order-details-dialog>
  </base-dialog>
</template>
<script>

import baseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import PaymentOrderDetailsDialog from '@/pages/assets-manage/payment-order-details/components/payment-order-details-dialog.vue'
import { paymentForm, paymentTable } from '../utils/make-config'
import { applyApi } from '@/api/financeAdmittanceApi'
import { formatDate } from '@/utils/auth/common'

export default {
  components: { baseForm, BaseDialog, BaseButton, IconButton, BaseTable, PaymentOrderDetailsDialog },
  props: {
    visible: Boolean,
    paymentData: String,
    paymentIndex: Number,
    assetInfoData: Array,
    contractName: String
  },
  data () {
    return {
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        gysId: this.$route.query.gysId
      },
      loadCount: 0,
      tableData: [],
      changeData: [],
      visibleOrderDetails: false,
      keyId: ''
    }
  },
  computed: {
    addForm () {
      return paymentForm(this)
    },
    paymentColumns () {
      return paymentTable(this)
    },
    api () {
      return applyApi
    },
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }

  },
  watch: {
    visible (val) {
      if (val) {
        this.tableData = []
        this.changeData = []
        this.queryParas = {
          pageSize: 10,
          pageIndex: 1,
          gysId: this.$route.query.gysId
        }
        this.$nextTick(() => {
          const paymentOrderArr = []
          this.assetInfoData.forEach((item, index) => {
            if (index !== this.paymentIndex) {
              paymentOrderArr.push(item.easNo)
            }
          })
          if (paymentOrderArr.length > 0) {
            this.$set(this.queryParas, 'paymentOrderAr', paymentOrderArr.join(','))
          }
          // 设置默认时间&带入合同名
          const myDate = new Date()
          const startData = myDate.getFullYear() + '-' + '01' + '-' + '01'
          this.$set(this.queryParas, 'changeTime', [startData, formatDate(myDate.getTime(), 'YY/MM/DD')])
          this.$set(this.queryParas, 'contractName', this.contractName)
          this.handleFilter()
        })
      }
    },
    tableData: {
      handler (val) {
        if (val.length > 0) {
          const that = this
          that.$refs.tableData.clearSelection()
          this.$nextTick(() => {
            // 回显选中的选项
            that.$refs.tableData.clearSelection()
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.assetInfoData.length; j++) {
                if (that.tableData[i].easNo === that.assetInfoData[j].easNo) {
                  that.$refs.tableData.toggleRowSelection(that.tableData[i], true)
                }
              }
            }
            // 显示选中的数据
            for (let i = 0; i < that.tableData.length; i++) {
              for (let j = 0; j < that.changeData.length; j++) {
                if (that.tableData[i].easNo === that.changeData[j].easNo) {
                  that.$refs.tableData.toggleRowSelection(that.tableData[i], true)
                }
              }
            }
          })
        }
      },
      deep: true
    },
    'queryParas.changeTime': {
      handler (val) {
        if (val && val.length > 0) {
          this.$set(this.queryParas, 'startDate', val[0])
          this.$set(this.queryParas, 'endDate', val[1])
        } else {
          this.$set(this.queryParas, 'startDate', '')
          this.$set(this.queryParas, 'endDate', '')
        }
      },
      deep: true
    }
  },
  mounted () {
  },
  methods: {
    handleFilter () {
      this.loadCount++
    },
    resetFrom () {
      this.$set(this.queryParas, 'contractName', '')
      this.$set(this.queryParas, 'pageSize', 10)
      this.$set(this.queryParas, 'pageIndex', 1)
      this.$set(this.queryParas, 'changeTime', [])
      this.$set(this.queryParas, 'easNo', '')
      // this.queryParas = {
      //   pageSize: 10, // 每页显示的数据
      //   pageIndex: 1,
      //   gysId: this.$route.query.gysId
      // }
    },
    pageIndexChange (item) {
      this.queryParas.pageIndex = item
    },
    pageSizeChange (item) {
      this.queryParas.pageSize = item
      this.queryParas.pageIndex = 1
    },
    select (selection, row) {
      // 已有的数据删除
      this.changeData.forEach((item, index) => {
        if (item.easNo === row.easNo) {
          this.changeData.splice(index, 1)
        }
      })
      // console.log(this.changeData, ';changeData')
    },
    // 选中数据处理
    changeDatas (data) {
      if (data.length === 0) {
        const newArr = []
        this.changeData.forEach((item, index) => {
          if (this.tableData.indexOf(item) !== -1) {
            newArr.push(item)
          }
        })
        if (newArr.length > 0) {
          newArr.forEach((item) => {
            if (this.changeData.indexOf(item) !== -1) {
              this.changeData.splice(this.changeData.indexOf(item), 1)
            }
          })
        }
      }
      data.forEach(ele => {
        let state = true
        this.changeData.forEach(eles => {
          if (eles.easNo === ele.easNo) {
            state = false
          }
        })
        if (state) {
          this.changeData.push(ele)
        }
        if (this.changeData.length === 0) {
          this.changeData.push(ele)
        }
      })
    },
    // 查看付款单信息
    info (data) {
      console.log(data, 'data')
      this.keyId = data.keyId
      this.visibleOrderDetails = true
    },
    // 提交新增修改
    submit () {
      console.log(this.changeData, 'this.changeData')
      const easNoArr = []
      this.changeData.forEach((item) => {
        easNoArr.push(item.easNo)
      })
      const params = {
        easNos: easNoArr.join(',')
      }
      if (easNoArr.length > 0) {
        // 校验付款单是否已经使用
        this.api.check(params).then(res => {
          if (res.data && res.data.pass) {
            // if (this.contractName) {
            //   const newData = JSON.parse(JSON.stringify(this.changeData))
            //   const errData = []
            //   newData.forEach(item => {
            //     if (item.contractName !== this.contractName) {
            //       errData.push(item.contractName)
            //     }
            //   })
            //   if (errData.length > 0) {
            //     return this.warning(errData.join(',') + ' 与已选合同不一致,请重新选择')
            //   }
            // }
            this.$emit('addPaymentData', this.changeData)
            this.currentVisible = false
          } else {
            this.warning(res.data.errorMsg)
          }
        })
      } else {
        this.$emit('addPaymentData', [])
        this.currentVisible = false
      }
    }
  }
}
</script>
