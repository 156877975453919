<template>
  <div>
    <div class="top">
      <div style="flex: 1">
        <div class="left" v-if="pledgorList.length > 0">
          <div class="item">转让人名称：{{ pledgorList[0].name }}</div>
          <div class="item">
            统一社会信用代码：{{ pledgorList[0].creditCode }}
          </div>
        </div>
      </div>

      <div v-if="!isDetail" class="addbtn">
        <div class="addbutton" @click="add">
          <i class="el-icon-plus"></i>
        </div>
      </div>
    </div>
    <base-table
      ref="finContractInfo"
      :columns="fundSideConfig"
      :tableAttrs="{
        data: finContractInfosCapitalReq,
        stripe: true
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="index" slot-scope="scope">{{
        scope.$index + 1
      }}</template>
      <template slot="easNoHeader">
        <div class="fontColor">
          付款单
        </div>
      </template>
      <template slot="finContractNameHeader">
        <font v-if="!isDetail" style="color: #ee1c1c">*</font>合同名称
      </template>
      <template slot="finContractNamesHeader">
        <font v-if="!isDetail" style="color: #ee1c1c">*</font>实际合同名称
      </template>
      <template slot="pledgeValueHeader">
        <font v-if="!isDetail" style="color: #ee1c1c">*</font>转让价值(元)
      </template>
      <template slot="relLoanAmountHeader">
        <font v-if="!isDetail" style="color: #ee1c1c">*</font>对应融资额(元)
      </template>
      <template slot="pledgeRateHeader">
        <font v-if="!isDetail" style="color: #ee1c1c">*</font>质押率(%)
      </template>
      <!-- 付款单 -->
      <template slot="easNo" slot-scope="scope">
        <div style="display:flex">
          <el-input v-model="scope.row.easNo" placeholder="请选择" disabled>
          </el-input>
          <icon-button
            content="付款单选择"
            @click="addPaymentOrder(scope.row, scope.$index)"
            icon="iconfont iconxuanze"
          />
        </div>
      </template>
      <template slot="finContractName" slot-scope="scope">
        <new-base-select
          v-model="scope.row.finContractId"
          filterable
          @change="
            finContractChange(
              scope.row,
              scope.row.finContractId,
              pledgorList[0] ? pledgorList[0].contractData : [],
              finContractInfosCapitalReq,
              scope.$index
            )
          "
          :options="pledgorList[0] ? pledgorList[0].contractData : []"
          :selectedField="['keyId', 'contractName']"
          :disabled="!!(scope.row.easNo && scope.row.finContractId) || isDetail"
        ></new-base-select>
      </template>
      <template slot="finContractNames" slot-scope="scope">
        <sz-input
          v-model="scope.row.finContractName"
          placeholder="请输入"
          :disabled="isDetail"
        ></sz-input>
      </template>
      <template slot="invoiceNo" slot-scope="scope">
        <sz-input v-model="scope.row.invoiceNo" placeholder="请输入" :disabled="isDetail"></sz-input>
      </template>
      <template slot="projectDebtor" slot-scope="scope">
        <sz-input
          v-model="scope.row.projectDebtor"
          placeholder="请输入"
          :disabled="isDetail"
        ></sz-input>
      </template>
      <template slot="finContractCode" slot-scope="scope">
        <sz-input
          v-model="scope.row.finContractCode"
          placeholder="请输入"
          :disabled="true"
        ></sz-input>
      </template>
      <template slot="finContractAmount" slot-scope="scope">
        <amount-input
          v-model="scope.row.finContractAmount"
          placeholder="请输入"
          @input="
            finContractAmountInput(scope.row.finContractAmount, scope.$index)
          "
          type="1000Y"
          :disabled="isDetail"
        ></amount-input>
      </template>
      <template slot="pledgeValue" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeValue"
          placeholder="请输入"
          type="1000Y"
          @input="pledgeValueChange(scope.row.pledgeValue, scope.$index)"
          @change="changePledgeValue(scope.row.pledgeValue, scope.$index)"
          :disabled="isDetail"
        ></amount-input>
      </template>
      <template slot="relLoanAmount" slot-scope="scope">
        <amount-input
          v-model="scope.row.relLoanAmount"
          placeholder="请输入"
          type="1000Y"
          @input="changeRelLoanAmount(scope.row.relLoanAmount, scope.$index)"
          :disabled="isDetail"
        ></amount-input>
      </template>
      <template slot="pledgeRate" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeRate"
          placeholder="请输入"
          :disabled="true"
          type="Y"
        ></amount-input>
      </template>
      <template slot="action" slot-scope="scope" v-if="!isDetail">
        <IconButton
          content="删除"
          @click="remove(scope.row, scope.$index)"
          icon="iconfont iconshanchu1"
        ></IconButton>
      </template>
    </base-table>
    <!-- 付款单弹窗 -->
    <add-payment-order
      :visible.sync="paymantVisible"
      :paymentIndex="paymentIndex"
      :assetInfoData="finContractInfosCapitalReq"
      :paymentData="paymentData"
      :contractName="contractName"
      @addPaymentData="addPaymentData"
    ></add-payment-order>
  </div>
</template>

<script>
import SzInput from '@/components/input/sz-input/sz-input.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
// import BaseSelect from '@/components/common/base-select/base-select.vue'
import NewBaseSelect from '@/components/common/base-select/new-base-select.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import addPaymentOrder from '@/pages/business/financing-application-management/components/add-payment-order.vue'
import { fundSideConfig } from '../utils/config'
import { assetcontractApi, assetinfoApi } from '@/api/assetApi'

export default {
  components: {
    BaseTable,
    IconButton,
    AmountInput,
    SzInput,
    NewBaseSelect,
    addPaymentOrder
  },
  props: {
    pledgorList: Array,
    finContractInfosCapitalReq: Array,
    showRequired: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paymantVisible: false,
      paymentIndex: 0,
      paymentData: '',
      contractName: '',
      contractCode: ''
    }
  },
  // 计算属性 类似于data概念
  computed: {
    fundSideConfig () {
      return fundSideConfig(this)
    }
  },
  // 监控data中的数据变化
  watch: {
    finContractInfosCapitalReq: {
      handler (val) {
        this.$emit('changeFundSide', val)
      },
      deep: true
    },
    showRequired (val) {
      if (val) {
        const dom = document.getElementsByClassName('fontColor')
        dom[0].setAttribute('class', 'fontRed')
      } else {
        const dom = document.getElementsByClassName('fontRed')
        dom[0].setAttribute('class', 'fontColor')
      }
    }
  },
  // 方法集合
  methods: {
    // 合同对价
    finContractAmountInput (data, index) {
      // if (data - 10000000000 > 0) {
      //   this.warning('合同对价不能大于10亿')
      //   this.$set(this.finContractInfosCapitalReq[index], 'finContractAmount', '')
      // }
    },
    // 对应融资额修改
    changeRelLoanAmount (value, index) {
      if (value && this.finContractInfosCapitalReq[index].pledgeValue) {
        this.$set(
          this.finContractInfosCapitalReq[index],
          'pledgeRate',
          (
            (value / this.finContractInfosCapitalReq[index].pledgeValue) *
            100
          ).toFixed(2)
        )
      }
      if (!value) {
        this.$set(this.finContractInfosCapitalReq[index], 'pledgeRate', '')
      }
    },
    // 转让价值修改
    pledgeValueChange (value, index) {
      if (value === '0' || !value) {
        this.warning('请输入转让价值且转让价值必须大于0')
        return false
      }
      if (value && this.finContractInfosCapitalReq[index].relLoanAmount) {
        this.$set(
          this.finContractInfosCapitalReq[index],
          'pledgeRate',
          (
            (this.finContractInfosCapitalReq[index].relLoanAmount / value) *
            100
          ).toFixed(2)
        )
      }
    },
    // 资金方要求 转让价值校验
    changePledgeValue (value, index) {
      // if (this.finContractInfosCapitalReq[index].easNo) {
      //   if (
      //     value &&
      //     this.finContractInfosCapitalReq[index].paymentApplyAmount &&
      //     this.finContractInfosCapitalReq[index].paymentApplyAmount - value <= 0
      //   ) {
      //     this.warning('付款单金额必须大于转让价值')
      //     this.$set(this.finContractInfosCapitalReq[index], 'pledgeValue', '')
      //   }
      // }
    },

    finContractChange (data, item, list, dataList, index) {
      let status = true
      if (this.contractInfoData) {
        const isSelectCode = this.contractInfoData.some(
          item => item.finContractId === data.finContractId
        )
        if (isSelectCode) {
          this.$set(data, 'finContractId', '')
          return this.warning('合同不能相同，请重新选择')
        }
      }
      dataList.forEach((ele, indexs) => {
        if (ele.keyIds === item && index !== indexs) {
          status = false
        }
      })
      if (!status) {
        this.$set(data, 'finContractId', '')
        return this.warning('合同不能相同，请重新选择')
      }

      list.forEach(ele => {
        if (ele.keyId === item) {
          if (ele.pledgeValue <= '0') {
            this.warning('转让价值必须大于0')
          }
          data.projectDebtor = ele.xmgsName
          data.finContractCode = ele.contractCode
          data.finContractAmount = ele.contractAmount
          this.$set(data, 'finContractName', ele.contractName)
          data.contractName = ele.contractName
          data.pledgeValue = ele.pledgeValue
          data.keyIds = ele.keyId // 用户补充合同查询过滤
          data.xmgsId = ele.xmgsId
        }
      })
      if (!item) {
        data.projectDebtor = ''
        data.finContractCode = ''
        this.$set(data, 'finContractName', '')
        data.finContractAmount = ''
        data.contractName = ''
        data.pledgeValue = ''
        data.relLoanAmount = ''
        data.keyIds = ''
        data.xmgsId = ''
      }
      this.$emit('clearRepaymentPlan', '')
    },
    add () {
      const data = {
        finContractName: '',
        projectDebtor: '',
        finContractId: '',
        finContractCode: '',
        finContractAmount: '',
        pledgeValue: '',
        relLoanAmount: ''
      }
      this.finContractInfosCapitalReq.push(data)

      this.$emit('clearRepaymentPlan', '')
    },
    async remove (row, index) {
      // 删除后端绑定的付款单
      if (row.finContractId && row.easNo && row.keyId) {
        const res = await assetinfoApi.updateDelStatus({
          easNos: row.easNo,
          contractId: row.finContractId,
          finContractInfoKeyId: row.keyId
        })
        if (res.data && res.data.code === '1') {
          console.log(res.data.message)
        }
      }
      this.finContractInfosCapitalReq.splice(index, 1)

      this.$emit('clearRepaymentPlan', '')
    },
    // 添加付款单
    addPaymentOrder (data, index) {
      this.paymentIndex = index
      this.paymentData = data.easNo
      this.contractName = data.finContractName || ''
      this.contractCode = data.finContractCode
      this.paymantVisible = true
    },
    // 资产列表数据付款单赋值
    async addPaymentData (data) {
      // 选择付款单时清空转让价值
      if (data.length > 0) {
        const newData = JSON.parse(JSON.stringify(data))
        // 先插入选中的值 保证选的位置后插入
        if (newData.length > 0) {
          const addData = newData.map(item => {
            return {
              easNo: item.easNo,
              finContractId: item.contractid,
              paymentApplyAmount: item.paymentApplyAmount
            }
          })
          this.finContractInfosCapitalReq.splice(
            this.paymentIndex,
            1,
            ...addData
          )
        }
        const contractIds = newData.map(item => item.contractid)
        const params = {
          contractIds: [...new Set(contractIds)].join(',')
        }
        const res = await assetcontractApi.contractByContractIds(params)
        const contractInfos = res.data.contractInfos
        this.finContractInfosCapitalReq.forEach((item, index) => {
          contractInfos.forEach(ele => {
            if (item.finContractId === ele.keyId) {
              const obj = {
                easNo: item.easNo,
                finContractCode: ele.contractCode,
                finContractName: ele.contractName,
                finContractId: ele.keyId,
                finContractAmount: ele.contractAmount || '',
                xmgsName: ele.xmgsName || '',
                xmgsId: ele.xmgsId || '',
                projectId: ele.projectId || '',
                pledgeValue: '',
                gysId: ele.gysId,
                projectDebtor: ele.xmgsName || '',
                pledgeRate: '',
                relLoanAmount: '',
                paymentApplyAmount: item.paymentApplyAmount // 付款单申报金额
              }
              this.finContractInfosCapitalReq.splice(index, 1, obj)
            }
          })
        })
        console.log(
          this.finContractInfosCapitalReq,
          contractInfos,
          'this.finContractInfosCapitalReq'
        )
        // 处理相同合同不同付款单
        data.forEach((ele, index) => {
          const indexs = this.paymentIndex + index
          let obj = {}
          for (let i = 0; i < this.finContractInfosCapitalReq.length; i++) {
            if (this.finContractInfosCapitalReq[i].easNo === ele.easNo) {
              return false
            }
          }
          contractInfos.forEach(eles => {
            if (ele.contractid === eles.keyId) {
              obj = {
                easNo: ele.easNo,
                finContractCode: eles.contractCode,
                finContractName: eles.contractName,
                finContractId: eles.keyId,
                finContractAmount: eles.contractAmount || '',
                xmgsName: eles.xmgsName || '',
                xmgsId: eles.xmgsId || '',
                projectId: eles.projectId || '',
                pledgeValue: '',
                gysId: eles.gysId,
                projectDebtor: eles.xmgsName || '',
                pledgeRate: '',
                relLoanAmount: '',
                paymentApplyAmount: ele.paymentApplyAmount // 付款单申报金额
              }
            }
          })
          let state = false
          // 已经有的付款单不重新增加一行。
          this.finContractInfosCapitalReq.forEach(eles => {
            if (ele.easNo === eles.easNo) {
              state = true
            }
          })
          if (!state) {
            this.finContractInfosCapitalReq.splice(indexs, 1, obj)
          }
        })
      } else {
        this.finContractInfosCapitalReq.forEach(ele => {
          if (this.contractCode === ele.finContractCode) {
            this.$set(
              this.finContractInfosCapitalReq[this.paymentIndex],
              'easNo',
              ''
            )
            this.$set(
              this.finContractInfosCapitalReq[this.paymentIndex],
              'paymentApplyAmount',
              ''
            )
            this.$set(
              this.finContractInfosCapitalReq[this.paymentIndex],
              'pledgeValue',
              ''
            )
            // this.$set(this.finContractInfosCapitalReq[this.paymentIndex], 'projectDebtor', '')
            this.$set(
              this.finContractInfosCapitalReq[this.paymentIndex],
              'pledgeRate',
              ''
            )
            this.$set(
              this.finContractInfosCapitalReq[this.paymentIndex],
              'relLoanAmount',
              ''
            )
          }
        })
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.fontRed:before {
  content: "*";
  display: inline-block;
  width: 10px;
  height: 10px;
  color: #ee1c1c;
}
.top {
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    flex: 1;
    display: flex;
    .item {
      color: #38373a;
      font-size: 14px;
      padding-right: 150px;
    }
  }
  .addbtn {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;
    padding-right: 20px;
    .addbutton {
      background: #4a7cf0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;

      cursor: pointer;
      &:hover {
        background-color: #6996ff;
      }
    }
  }
}
</style>
