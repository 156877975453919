<template>
  <div class="pos">
    <div class="tips" v-if="message && type === '1'">提示：{{ message }}</div>
    <el-radio-group v-bind="$attrs" v-on="$listeners" >
      <el-radio
        v-for="(item, index) of $attrs.options"
        :label="item.value"
        :key="index"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>
    <!-- 非单笔 -->
    <div v-if="$attrs.value === '1'||$attrs.value === '2'" class="singleTransaction">
      <!-- <el-radio-group
        v-model="singleTransactionType"
        @change="changeSingleTransactionType"
      >
        <el-radio
          v-for="(item, index) of typeOptions"
          :label="item.value"
          :key="index"
          >{{ item.label }}</el-radio
        >
      </el-radio-group> -->

      <base-form
        :componentList="shareholderMeetingConfig"
        :formAttrs="{
          model: formData,
          labelWidth: '140px',
        }"
        :showBtns="false"
        class="formData"
        ref="ShareholderMeetingType"
       v-if="$attrs.value === '1'"

      />
      <base-table
        :columns="MeetingTableConfig"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        :showPage="false"
        v-if="$attrs.value === '2'"
      >
      <template slot="index" slot-scope="scope">
        {{scope.$index+1}}
      </template>
      <template slot="radio" slot-scope="scope">
         <el-radio v-model="scope.row.selected" :label="true" @change='changeRadio(scope.row,scope.$index)' >&nbsp;</el-radio>
      </template>
      <template slot="action" slot-scope="scope">
         <icon-button  content="查看"
          icon="iconfont iconchakan" @click="previewShow(scope.row)" />
      </template>
      </base-table>
      <!-- 文件预览 -->
        <!-- <pre-view-dialog
      :previsible.sync="previsible"
      :count="count"
      :fileId="fileId"
      :fileType="fileType"
      @dowload="dowload(fileId)"
    /> -->
    <pre-view :fileId="fileId" :isOpen='true' :fileType="fileType" :count="count" />
    </div>
  </div>
</template>

<script>
import PreView from '@/components/pre-view/pre-view.vue'
import { downAPi } from '@/api/fileApi'

import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { resolutionAPi } from '@/api/businessApi'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { MeetingConfig, MeetingTableConfig } from '../utils/config'
import { allSupportFileTypes } from '@/components/pre-view/pre-view.js'
import { downFile } from '@/utils/devUtils'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
export default {
  components: { BaseForm, BaseTable, IconButton, PreView },
  props: {
    props: {
      options: Array,
      singleTransactionType: String
    }
  },
  data () {
    return {
      message: '',
      tableData: [],

      type: '0',
      fileId: '',
      fileType: '',
      previsible: false,
      count: 0
    }
  },
  // 计算属性 类似于data概念
  computed: {
    MeetingTableConfig () {
      return MeetingTableConfig(this)
    },
    shareholderMeetingConfig () {
      return MeetingConfig(this)
    },
    api () {
      return resolutionAPi
    },
    formData: {
      get () {
        const obj = this.$attrs.shareholderResultValidInfo

        return obj
      },
      set (val) {
        // val.resultValidTimeStart = val.time[0]
        // val.resultValidTimeEnd = val.time[1]
        this.$emit('getValidInfo', val)
      }
    },
    singleTransactionType: {
      get () {
        if (this.$attrs.singleTransactionType === '2') {
          // this.changeSingleTransactionType('2')
        }
        return this.$attrs.singleTransactionType
      },
      set (val) {
        this.$emit('changeSingleTransactionType', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    $attrs: {
      handler (val) {
        if (this.type !== val.value) {
          this.change(val.value)
        }
      },
      deep: true
    },
    formData: {
      handler (val) {
        this.$nextTick(() => {
          if (this.$refs.ShareholderMeetingType) {
            this.$refs.ShareholderMeetingType.clearValidate()
          }
        })
      },
      deep: true
    }
  },
  // 方法集合
  methods: {
    iconClick () {
      console.log(888)
    },
    // 校验数据完整
    verification () {
      let pass = false

      if (this.formData.resultValidTimeStart && this.formData.validTimeNum && this.formData.validTimeUnit) {
        pass = true
      }

      // 验证通过调用自动计算计算结束时间
      if (pass) {
        const params = {
          resultValidTimeStart: this.formData.resultValidTimeStart,
          validTimeNum: this.formData.validTimeNum,
          validTimeUnit: this.formData.validTimeUnit
        }
        this.api.getResultValidTimeEnd(params).then(res => {
          console.log(res.data, 'getResultValidTimeEnd')
          this.$set(this.formData, 'resultValidTimeEnd', res.data)
        })
      }
    },

    // 选择股东会决议有效性
    changeRadio (data, key) {
      console.log(data.selected, 'data.selected')
      if (data.selected) {
        this.api.getValidInfo({ financeAmount: this.$attrs.financeAmount, keyId: data.keyId }).then(res => {
          this.tableData.forEach((item, index) => {
            if (index !== key) {
              this.$set(this.tableData[index], 'selected', false)
            }
          })

          this.$emit('getValidInfo', data)
          if (!res.data.status) {
            this.message = res.data.errorMsg
          } else {
            this.message = ''
          }
        })
      }
    },
    // 下载文件
    dowload (fileId) {
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 预览
    previewShow (data) {
      this.fileId = data.fileId

      this.fileType = data.fileName ? data.fileName.split('.').pop() : ''

      if (!this.fileType || !allSupportFileTypes.includes(this.fileType)) {
        this.warning(`不能预览的文件类型：[${this.fileType}]`)
        return
      }

      this.count++
    },
    // 修改年月日
    changeValidTimeUnit (val) {
      this.$emit('changeValidTimeUnit', val)
      this.verification()
    },
    // 非单笔选择类型
    changeSingleTransactionType (val) {
      this.message = ''
      this.$emit('getValidInfo', '')
      console.log(val, '我执行了')
      if (val === '2') {
        if (!this.$attrs.financeAmount) {
          this.$attrs.financeAmount = 0
        }

        const params = {
          businessId: this.$route.query.keyId,
          orgId: this.$attrs.gysId,
          financeAmount: this.$attrs.financeAmount,
          resultValidTimeFileType: this.$attrs.resultValidTimeFileType
        }
        this.api.getResultValid(params).then((res) => {
          if (res.data) {
            this.tableData = res.data
            this.tableData.forEach((item) => {
              if (item.selected) {
                this.api.getValidInfo({ financeAmount: this.$attrs.financeAmount, keyId: item.keyId }).then(res => {
                  if (!res.data.status) {
                    this.message = res.data.errorMsg
                  }
                  this.$emit('getValidInfo', item)
                })
              }
            })
          } else {
            this.warning('无可续用的文件')
          }
        })
        // this.api.getValidInfo(params).then(res => {
        //   if (res.data) {
        //     console.log(this.$attrs, this.shareholderResultValidInfo)
        //     this.$emit('getValidInfo', res.data)
        //     if (!res.data.status) {
        //       this.message = res.data.errorMsg
        //     }
        //   } else {
        //     this.$emit('changeSingleTransactionType', '1')
        //     this.warning('无可续用的股东会决议')
        //   }
        // })
      }
    },
    change (val) {
      this.type = val
      console.log('触发了change')
      if (val === '1' || val === '2') {
        // this.$emit('changeSingleTransactionType', val)
        this.changeSingleTransactionType(val)
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.tips {
  position: absolute;
  left: 310px;
  top: 52px;
  color: #e6a23c;
}
.pos {
  position: relative;
}
.singleTransaction {
  // background: #f9f9f6;
  background: #fff;
  width: 100%;
  padding: 20px 11px;
}
</style>
