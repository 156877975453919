/* eslint-disable standard/no-callback-literal */
import BaseSelect from '@/components/common/base-select/base-select.vue'
import NewBaseSelect from '@/components/common/base-select/new-base-select.vue'
// import Storage from '@/utils/storage'

import { getDictLists } from '@/filters/fromDict'
import financingInput from '../components/financing-input.vue'
import repaymentType from '../components/repayment-type.vue'
// import { validbusinessNo } from '@/utils/validate'
import ProportionInput from '@/components/input/proportion-input/proportion-input.vue'
import amountInput from '@/components/input/amount-input/amount-input.vue'
import supplierSelect from '@/components/packages/supplier-select/supplier-select'
import fileUpdate from '../components/file-update'
import SelectAccount from '../components/select-account'
import ShareholderMeetingType from '../components/shareholder-meeting-type.vue'
import { formatDate } from '@/utils/auth/common'
// import termOfValidity from '../components/term-of-validity.vue'
import termOfValidity from '@/components/packages/term-of-validity/term-of-validity.vue'
import FileTypeSelection from '../components/file-type-selection'
import SigningMethod from '../components/signing-method'
// import SupplementaryInformation from '../components/supplementary-information'
const checkFinanceRate = (rule, value, callback) => {
  if (!value) {
    return callback('请输入综合融资利率')
  } else {
    if (parseFloat(value) === 0) {
      return callback('请输入正确的综合融资利率')
    } else {
      const reg = /^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/
      if (reg.test(value)) {
        callback()
      } else {
        return callback('请输入正确的综合融资利率')
      }
    }
  }
}
const checkCostPrice = (rule, value, callback) => {
  console.log(value)
  if (!value) {
    return callback('请输入资方成本价格')
  } else {
    if (parseFloat(value) === 0) {
      return callback('请输入正确的资方成本价格')
    } else {
      const reg = /^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/
      if (reg.test(value)) {
        callback()
      } else {
        return callback('请输入正确的资方成本价格')
      }
    }
  }
}
// const checkFinanceAmount = (rule, value, callback) => {
//   if (!value) {
//     return callback('请输入融资金额')
//   } else {
//     let reg = /^(([0-9]{1,12})|([1-9]{1,10}\.\d{1,2}))$/
//     if (reg.test(value)) {
//       callback()
//     } else {
//       return callback('请输入正确的融资金额')
//     }
//   }
// }

const checkCapitalCost = (rule, value, callback) => {
  const reg = /^100$|^(\d|[0-9]\d)(\.\d{1,2})?$/
  if (value) {
    if (reg.test(value)) {
      callback()
    } else {
      return callback('请输入正确的资金年化成本')
    }
  }
}
const checkServiceRate = (rule, value, callback) => {
  const reg = /^100$|^(\d|[0-9]\d)(\.\d{1,2})?$/
  if (value) {
    if (reg.test(value)) {
      callback()
    } else {
      return callback('请输入正确的年化服务费率')
    }
  }
}
const checkYearRate = (rule, value, callback) => {
  const reg = /^100$|^(\d|[0-9]\d)(\.\d{1,2})?$/
  if (value) {
    if (reg.test(value)) {
      callback()
    } else {
      return callback('请输入正确的年化利率')
    }
  }
}

const financeTermValidator = (rule, value, callback) => {
  // const regn = /^([1-9]?\d)$/
  if (!value) {
    return callback('请输入融资月数')
  } else if (value - 12 > 0) {
    return callback('请输入正确的月数(大于0小于等于12)')
  } else if (value === 0 || value === '0') {
    return callback('请输入正确的月数(大于0小于等于12)')
  } else {
    return callback()
  }
}
const financeAmountValidator = (rule, value, callback) => {
  if (!value) {
    return callback('请输入融资金额')
  } else if (10000 - value > 0) {
    return callback('融资金额需大于等于10000')
  } else {
    return callback()
  }
}
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        filterable: true,
        selectedField: ['keyId', 'enterpriseName'],
        maxlength: 50,
        ref: 'searchSelect'
      },
      tag: supplierSelect
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REVIEW_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeStatus',
      attrs: {
        placeholder: '请选择',
        options: content.fileMakeData,
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      isHidden: content.fold
    }
  ]
}
export const MeetingConfig = (content) => {
  const pickerOptions = {
    disabledDate (v) {
      return v.getTime() <= content.formData.resultValidTimeStart
    }
  }

  const validTimeNumValidator = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('请输入有效期'))
    } else {
      if (!content.formData.validTimeUnit) {
        return callback(new Error('请选择有效期单位'))
      } else {
        return callback()
      }
    }
  }
  return [
    {
      label: '有效期',
      prop: 'validTimeNum',
      rules: [
        {
          required: true,
          validator: validTimeNumValidator,
          trigger: ['change', 'blur']
        }
      ],

      span: 5,
      attrs: {

        placeholder: '请输入有效期',
        maxlength: 5,
        type: 'ZS',
        validTimeUnit: content.formData.validTimeUnit
      },
      on: {
        blurInput () {
          content.verification()
        },
        update (val) {
          content.changeValidTimeUnit(val)
        }
      },
      tag: termOfValidity
    },

    {
      label: '有效期开始时间', // 开始时间
      prop: 'resultValidTimeStart',
      rules: [
        {
          required: true,
          message: '请选择有效期开始时间',
          trigger: ['change', 'blur']
        }
      ],

      span: 5,
      tag: 'el-date-picker',
      attrs: {
        'value-format': 'timestamp',

        placeholder: '请选择有效期开始时间',
        editable: false,
        clearable: false
      },
      on: {
        change (data) {
          content.verification()
        }
      }
    },
    {
      label: '有效期结束时间', // 结束时间
      prop: 'resultValidTimeEnd',
      rules: [
        {

          required: true,
          message: '请选择有效期开始时间',
          trigger: ['change', 'blur']
        }
      ],

      span: 5,
      tag: 'el-date-picker',
      attrs: {
        'value-format': 'timestamp',
        disabled: true,
        placeholder: '请选择有效期开始时间',
        editable: false,
        clearable: false,
        'picker-options': pickerOptions
      }
    },
    {
      label: '担保额度(亿元)',
      prop: 'guaranteeQuota',
      rules: [
        { required: true, message: '请输入担保额度', trigger: ['blur', 'change'] }
      ],
      span: 5,
      attrs: {
        placeholder: '请输入担保额度',
        type: '十亿'
      },
      tag: amountInput
    },
    {
      label: '是否为循环额度',
      prop: 'isCycle',
      rules: [
        { required: true, message: '请选择是否为循环额度', trigger: ['blur', 'change'] }
      ],
      span: 5,

      attrs: {
        options: [{ keyId: '2', name: '是' }, { keyId: '1', name: '否' }],
        selectedField: ['keyId', 'name']
      },
      tag: BaseSelect
    },
    {
      label: '业务审批时间',
      prop: 'busiApproveTime',
      rules: [
        {
          required: true,
          message: '请选择业务审批时间',
          trigger: ['change', 'blur']
        }
      ],
      span: 5,
      tag: 'el-date-picker',
      attrs: {
        disabled: content.singleTransactionType === '2',
        placeholder: '请选择业务审批时间',
        editable: false
      }
    }
  ]
}
export const MeetingTableConfig = (content) => {
  return [
    {
      label: '选择',
      prop: 'radio'
    },
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '有效期',
      prop: 'termValidity',
      formatter: row => {
        const validTimeNum = row.validTimeNum
        const [obj] = getDictLists('DATE_UNIT').filter(item => item.dictId === row.validTimeUnit.toString())
        return obj ? validTimeNum + obj.dictName : '-'
      }
    },
    {
      label: '生效日期',
      prop: 'resultValidTimeStart',
      formatter: row => {
        return formatDate(row.resultValidTimeStart, 'YY-MM-DD')
      }
    },
    {
      label: '失效日期',
      prop: 'resultValidTimeEnd',
      formatter: row => {
        return formatDate(row.resultValidTimeEnd, 'YY-MM-DD')
      }
    },
    {
      label: '总额度(元)',
      prop: 'guaranteeQuota',
      formatter: row => {
        return (row.guaranteeQuota ? row.guaranteeQuota.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '剩余额度(元)',
      prop: 'surplusGuaranteeQuota',
      formatter: row => {
        return (row.surplusGuaranteeQuota ? row.surplusGuaranteeQuota.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '--')
      }
    },
    {
      label: '文件',
      prop: 'action'
    }

  ]
}
export const SupplementaryInformationConfig = (content) => {
  const shareholdersArrivingNumValidator = (rule, value, callback) => {
    const regn = /^[0-9]{1,5}$/g
    if (!value) {
      return callback('请输入应到股东人数')
    } else if (!regn.test(value)) {
      return callback('请输入请输入5位以内的整数')
    } else {
      return callback()
    }
  }
  return [
    {
      label: '应到股东人数',
      prop: 'shareholdersArrivingNum',
      span: 24,
      rules: [{ required: true, validator: shareholdersArrivingNumValidator, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入应到股东人数'
      }
    }
  ]
}
// 股东会决议有效性
export const shareholderMeetingConfig = (content) => {
  // const circleQuotaOptions = [{ label: '单笔', value: '0' }, { label: '重出', value: '1' }, { label: '续用', value: '2' }]
  const circleQuotaOptions = [{ label: '单笔', value: '0' }]
  // const Options = [{ label: '股东会决议', value: '0' }, { label: '董事会决议', value: '1' }, { label: '两者均设置', value: '2' }]
  const Options = [{ label: '股东会决议', value: '0' }]
  const placeholder1 = '请输入：' + '\n' + '【示例】' + '\n' + '1.股东会/董事会签署成员名字、表决占股/人数比例' + '\n' + '2. XXX（身份证号：XXXXXX96003126238）出具实控人《承诺函》（非全体股东签署条件）' + '\n' + '3.单笔，本次有效/非单笔，一年内有效（预计2021.10.11-2022.10.11，具体以签署时间为准），融资及担保额度x万元，本次业务审批（预计2021.10.11-2022.4.11，具体以签署时间为准）在有效期内' + '\n' + '4.框架股决已于2021年9月25日由股东XXX、股东XXX签署，一年内有效（202X.X.XX-2022.9.25），融资及担保额度x万元，已使用X万元（融资期限），剩余y万元可覆盖本次融资本息，本次业务审批（预计2021.10.11-2022.4.11，具体以签署时间为准）在有效期内 （如无可删除）'
  return [
    {
      label: '选择文件类型',
      prop: 'resultValidTimeFileType',
      span: 24,
      rules: [{ required: false, message: '请选择选择文件类型', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        options: Options
      },
      on: {
        change (data) {
          console.log(data, '222')
          if (content.formData.resultValidTimeType === '2') {
            content.$nextTick(() => {
              content.$refs.shareholderMeeting.$refs.ShareholderMeetingType.changeSingleTransactionType('2')
            })
          }
        }
      },
      isHidden: true,
      tag: FileTypeSelection
    },
    {
      label: '股东决议有效性',
      prop: 'resultValidTimeType',
      rules: [{ required: false, message: '请选择股东决议有效性', trigger: 'blur' }],
      span: 24,
      attrs: {
        shareholderResultValidInfo: content.shareholderResultValidInfo,
        placeholder: '请选择',
        options: circleQuotaOptions,
        gysId: content.gysId,
        financeAmount: content.financeAmount,
        singleTransactionType: content.singleTransactionType,
        resultValidTimeFileType: content.formData.resultValidTimeFileType,
        ref: 'ShareholderMeetingType'
      },
      isHidden: true,
      on: {
        getValidInfo (data) {
          console.log(data, 1)
          data.validRemark = content.formData.shareholderResultValidInfo.validRemark ? content.formData.shareholderResultValidInfo.validRemark : ''
          content.getValidInfo(data)
        },
        changeSingleTransactionType (data) {
          content.changeSingleTransactionType(data)
        },
        changeValidTimeUnit (data) {
          content.changeValidTimeUnit(data)
        }
      },
      tag: ShareholderMeetingType
    },
    // {
    //   label: '',
    //   prop: 'shareholdersArrivingNum',
    //   span: 6,
    //   rules: [{ required: false, message: '请输入应到股东人数', trigger: 'blur' }],
    //   attrs: {
    //     placeholder: '请输入应到股东人数',
    //     shareholdersArrivingNum: content.formData.shareholdersArrivingNum
    //   },
    //   tag: SupplementaryInformation,
    //   isHidden: content.formData.resultValidTimeType === '2',
    //   on: {
    //     changeShareholdersArrivingNum (val) {
    //       content.formData.shareholderResultValidInfo.shareholdersArrivingNum = val
    //       console.log(content.formData, 'changeShareholdersArrivingNum')
    //     }
    //   }
    // }
    {
      label: '股东会决议有效性总结',
      prop: 'validRemark',
      span: 24,
      showBtn: true,
      rules: [{ required: true, message: '请输入股东会决议有效性总结', trigger: 'change' }],
      attrs: {
        btnText: '',
        iconfont: 'iconfont iconbianji3',
        placeholder: placeholder1,
        type: 'textarea',
        rows: '8',
        maxlength: '500',
        showTipText: '点击代入示例文案',
        textValue: '1.股东会/董事会签署成员名字、表决占股/人数比例' + '\n' + '2. XXX（身份证号：XXXXXX96003126238）出具实控人《承诺函》（非全体股东签署条件）' + '\n' + '3.单笔，本次有效/非单笔，一年内有效（预计2021.10.11-2022.10.11，具体以签署时间为准），融资及担保额度x万元，本次业务审批（预计2021.10.11-2022.4.11，具体以签署时间为准）在有效期内' + '\n' + '4.框架股决已于2021年9月25日由股东XXX、股东XXX签署，一年内有效（202X.X.XX-2022.9.25），融资及担保额度x万元，已使用X万元（融资期限），剩余y万元可覆盖本次融资本息，本次业务审批（预计2021.10.11-2022.4.11，具体以签署时间为准）在有效期内 （如无可删除）'
      },
      on: {

      }

    }

  ]
}
// 资金方要求
export const fundSideConfig = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    // {
    //   label: '付款单',
    //   prop: 'easNo',
    //   HiddenOverflow: true,
    //   width: '250px',
    //   isHidden: (Storage.getLocal('contractInfo').finaaceProductName === '稳享付')
    // },
    {
      label: '合同名称',
      prop: 'finContractName',
      HiddenOverflow: true
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      HiddenOverflow: true
    },
    {
      label: '实际合同名称',
      prop: 'finContractNames',
      HiddenOverflow: true,
      width: '300px'
    },
    {
      label: '项目债务人',
      prop: 'projectDebtor',
      HiddenOverflow: true
    },
    {
      label: '合同对价',
      prop: 'finContractAmount',
      HiddenOverflow: true
    },
    {
      label: '发票编号',
      prop: 'invoiceNo',
      HiddenOverflow: true
    },
    {
      label: '转让价值',
      prop: 'pledgeValue',
      HiddenOverflow: true
    },
    {
      label: '对应融资额',
      prop: 'relLoanAmount',
      HiddenOverflow: true
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 表格form
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '150px'
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      minWidth: '150px',
      align: 'left',
      'show-overflow-tooltip': true
    },
    {
      label: '申请金额(元)',
      prop: 'applyAmount',
      minWidth: '150px',
      align: 'right',
      formatter: row => {
        return (row.applyAmount ? row.applyAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatusName',
      minWidth: '150px',
      // formatter: row => {
      //   const [obj] = getDictLists('REVIEW_STATUS').filter(item => item.dictId === row.reviewStatus.toString())
      //   return obj ? obj.dictName : '-'
      // }
      formatter: row => {
        return row.reviewStatusName || '--'
      }
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      minWidth: '120px',
      fixed: 'right'
    }
  ]
}
// 评审会问题及落实
export const reviewMeetingConfig = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '问题描述',
      prop: 'description',
      minWidth: '150px'
    },
    {
      label: '落实情况',
      prop: 'situation',
      minWidth: '150px'

    }

  ]
}
// 融资方案信息
export const schemeInfo = (content, index) => {
  const placeholder1 = '请输入：' + '\n' + '【示例：服务费按实际用款金额与时间每月支付，到期一次性偿还本金及剩余服务费，若公路一提前结算或借款人提前还款则允许提前扣划。】'
  const placeholder2 = '请输入：' + '\n' + '【示例：款项用于XXXXXXXXXX项目施工款以及材料款】'
  const placeholder3 = '请输入：' + '\n' + '【示例：融资项目XX月XX日申请编号FK20220245456456的应收进度款，公司经营资金。】'
  // const span = 5
  return [
    {
      label: '借款人',
      prop: 'mainBorrowerId',
      rules: [{ required: true, message: '请选择借款人', trigger: ['change', 'blur'] }],
      attrs: {
        placeholder: '请选择',
        options: content.personsDatas,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true

      },
      on: {
        change: (data, arr, index) => {
          content.personsChange(data, arr, index)
        }
      },
      tag: BaseSelect,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      rules: [{ required: true, message: '请输入统一社会信用代码', trigger: ['change', 'blur'] }],
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '共借人',
      prop: 'coBorrowerId',
      attrs: {
        placeholder: '请选择',
        options: content.schemeInfoList[index].personsData,
        selectedField: ['personKeyId', 'personnerName'],
        filterable: true
      },
      isHidden: content.dictType === '3' || content.dictType === '4',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      on: {
        change: (data, arr, index) => {
          content.personnerChange(data, arr, index)
        }
      },
      tag: BaseSelect,
      rules: [{ required: true, message: '请选择共借人', trigger: ['change', 'blur'] }]
    },
    {
      label: '身份证号',
      prop: 'idCard',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      isHidden: content.dictType === '3' || content.dictType === '4',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请在供应商管理功能中预先维护人员身份证号码', trigger: ['change', 'blur'] }]
    },
    {
      label: '融资金额(元)',
      prop: 'financeAmount',
      attrs: {
        placeholder: '请输入',
        sum: true,

        type: 'Y'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: amountInput,
      on: {
        input: (data) => {
          if (parseFloat(data) >= 100000000) {
            content.schemeInfoList[index].financeAmount = '99999999.99'
          }
          // content.schemeInfoList[index].financeAmount = data.toString().replace(/^[0]+[0-9]*$/gi, '')
          // if (content.schemeInfoList[index].financeAmount.indexOf('0.') !== -1 && content.schemeInfoList[index].financeAmount.substr(0, 1) === '0') {
          //   content.schemeInfoList[index].financeAmount = ''
          // }
        },
        change: () => {
          const FinRepayPlanDTO = {
            advanceRepayTerm: '', // 提前还款期限
            chargeParty: '', // 收费主体
            finPlanId: '', // 融资方案ID
            repayAmount: '', // 还款金额
            repayRate: '', // 还款利率
            repayStatus: content.schemeInfoList[index].repayTypeCode ? content.schemeInfoList[index].repayTypeCode : '0', // 还款方式
            repayTime: '', // 还款时间(多个时间用逗号隔开)
            repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
          }

          const data = {
            data: FinRepayPlanDTO,
            key: index
          }
          content.repaymentType(data)
          if (content.dictType === '4') {
            // 中小担 重置股东会决议
            content.clearShareholderMeeting()
          }
        }
      },
      rules: [{ required: true, validator: financeAmountValidator, trigger: ['change', 'blur'] }]
    },
    {
      label: '产品名称',
      prop: 'finaaceProductName',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },

      rules: [{ required: true, message: '请输入产品名称', trigger: ['blur'] }]
    },
    {
      label: '资金方',
      showTip: content.dictType === '4' ? '到期一次性还本,按月付息，可提前部分还款。可循环额度' : '',
      prop: 'capitalSideName', // capitalSideName
      attrs: {
        disabled: true
        // placeholder: '请选择',
        // options: content.providertData,
        // selectedField: ['keyId', 'capitalAndProductName']
      },
      // on: {
      //   change: (data, arr, index) => {
      //     const [obj] = content.providertData.filter(item => item.keyId === data)
      //     content.schemeInfoList[index].capitalSideName = obj ? obj.capitalSideName : ''
      //     content.schemeInfoList[index].surplusQuota = obj ? obj.surplusQuota : ''
      //     content.schemeInfoList[index].capitalSideProductId = obj ? obj.capitalSideProductId : ''
      //     // content.productRate(obj ? obj.capitalSideName : '', index)
      //     content.setproductRate(obj.productRate, index)
      //   }
      // },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      // tag: BaseSelect,
      rules: [{ required: true, message: '请选择资金方', trigger: 'change' }]
      // isHidden: content.dictType === '4'
    },
    {
      label: '存量余额(元)',
      prop: 'stockBalance',
      attrs: {
        placeholder: '请输入',
        sum: true,

        type: 'Y'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: amountInput,
      on: {

      },
      rules: [{ required: true, message: '请输入存量余额', trigger: ['change', 'blur'] }]
    },
    {
      label: '融资期限(月)',
      prop: 'financeTerm',
      attrs: {
        placeholder: '请输入',
        maxlength: 2
      },
      on: {
        input: (data) => {
          content.schemeInfoList[index].financeTerm = data.replace(/[^\d]|^[0]/g, '')
          content.schemeInfoList.forEach((ele, i) => { // 控制二级模式下融资期限统一
            if (i !== index) {
              content.$set(ele, 'financeTerm', content.schemeInfoList[index].financeTerm)
            }
          })
        },
        change: () => {
          const FinRepayPlanDTO = {
            advanceRepayTerm: '', // 提前还款期限
            chargeParty: '', // 收费主体
            finPlanId: '', // 融资方案ID
            repayAmount: '', // 还款金额
            repayRate: '', // 还款利率
            repayStatus: content.schemeInfoList[index].repayTypeCode ? content.schemeInfoList[index].repayTypeCode : '0', // 还款方式
            repayTime: '', // 还款时间(多个时间用逗号隔开)
            repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
          }

          const data = {
            data: FinRepayPlanDTO,
            key: index
          }
          content.repaymentType(data)
        }
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, validator: financeTermValidator, trigger: ['change', 'blur'] }]
    },
    {
      label: '综合融资利率(%)',
      prop: 'financeRate',
      attrs: {
        placeholder: '请输入',
        maxLength: 5,

        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [
        { required: true, validator: checkFinanceRate, trigger: ['change', 'blur'] }
      ],
      tag: amountInput,
      on: {
        blur: (data) => {
          if (content.schemeInfoList[index].financeRate) {
            content.schemeInfoList[index].financeRate = content.schemeInfoList[index].financeRate * 1
          }

          if (content.schemeInfoList[index].financeRate - 100 > 0) {
            content.schemeInfoList[index].financeRate = ''
            content.schemeInfoList[index].serviceRateStr = ''
          } else {
            if (content.schemeInfoList[index].financeRate && (content.schemeInfoList[index].costPrice === 0 || content.schemeInfoList[index].costPrice)) {
              if (content.schemeInfoList[index].financeRate * 1 - content.schemeInfoList[index].costPrice * 1 < 0) {
                content.warning('综合融资利率必须大于资方成本价格')
                content.schemeInfoList[index].serviceRateStr = ''
                content.schemeInfoList[index].financeRate = ''
              } else {
                content.schemeInfoList[index].serviceRateStr = (content.schemeInfoList[index].financeRate * 1000 - content.schemeInfoList[index].costPrice * 1000) / 1000
                content.schemeInfoList[index].finServiceCollectInfo = []
              }
            }
          }
        }
      }
    },
    {
      label: '资方成本价格(%)',
      prop: 'costPrice',
      attrs: {
        placeholder: '请输入',
        maxLength: 5,
        disabled: content.dictType !== '3',
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [
        { required: true, validator: checkCostPrice, trigger: ['change', 'blur'] }
      ],
      tag: amountInput,
      on: {
        blur: (data) => {
          if (content.schemeInfoList[index].financeRate && content.schemeInfoList[index].costPrice) {
            if (content.schemeInfoList[index].financeRate * 1 - content.schemeInfoList[index].costPrice * 1 < 0) {
              content.warning('综合融资利率必须大于资方成本价格')
              content.schemeInfoList[index].serviceRateStr = ''
              content.schemeInfoList[index].costPrice = ''
            } else {
              content.schemeInfoList[index].serviceRateStr = (content.schemeInfoList[index].financeRate * 1000 - content.schemeInfoList[index].costPrice * 1000) / 1000
              content.schemeInfoList[index].finServiceCollectInfo = []
            }
          }
        }
      }
    },
    {
      label: '融资服务收费主体',
      prop: 'loanAssistanceOrg',
      attrs: {
        placeholder: '请选择',
        type: 'textarea',
        maxlength: '200',
        rows: 4,
        options: content.mainPartData,
        selectedField: ['enterpriseName', 'enterpriseName']
      },
      on: {
        change: (data, arr, index) => {
          content.mainPartData.forEach(ele => {
            if (ele.enterpriseName === content.schemeInfoList[index].loanAssistanceOrg) {
              content.schemeInfoList[index].loanAssistanceOrgId = ele.keyId
            }
          })
          content.schemeInfoList.forEach((ele, i) => { // 控制二级模式下签约主体统一
            if (i !== index) {
              content.$set(ele, 'loanAssistanceOrg', content.schemeInfoList[index].loanAssistanceOrg)
              content.$set(ele, 'loanAssistanceOrgId', content.schemeInfoList[index].loanAssistanceOrgId)
            }
          })
        }
      },
      rules: [{ required: true, message: '请选择融资服务收费主体', trigger: ['blur', 'change'] }],
      tag: BaseSelect,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '服务费率(%)',
      prop: 'serviceRateStr',
      hidden: content.hiddentServiceRate,
      attrs: {
        // hidden: true,
        placeholder: '请输入',
        disabled: true,
        showBtn: !content.disabled,
        serviceInput: true,
        schemeInfoList: content.schemeInfoList,
        payeeData: content.payeeData
      },
      on: {
        serviceTariffing: (data) => {
          content.serviceTariffing(data)
        },
        blur: (data) => {
          console.log(content.schemeInfoList[index].serviceRateStr, 'data')
          if (content.schemeInfoList[index].serviceRateStr === '0.0') {
            content.schemeInfoList[index].serviceRateStr = ''
          }
          if (content.schemeInfoList[index].serviceRateStr - 100 > 0) {
            content.schemeInfoList[index].serviceRateStr = ''
          }
        },
        input: (data) => {
          if (!content.schemeInfoList[index].serviceRateState) {
            // data = data.replace(/^[0]+[0-9]*$/gi, '')
            if (parseFloat(data) === 100) {
              content.schemeInfoList[index].serviceRateStr = '10'
            } else {
              content.schemeInfoList[index].serviceRateStr = data
            }
            if (content.schemeInfoList[index].serviceRateStr.indexOf('0.00') !== -1) {
              content.schemeInfoList[index].serviceRateStr = ''
            }
          }
        }
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: financingInput,
      rules: [{ required: true, message: '请输入服务费率', trigger: ['change', 'blur'] }]
    },

    {
      label: '折价率(%)',
      prop: 'totalPledgeRate',
      showTip: content.dictType === '3' && content.schemeInfoList[0].finaaceProductName === '稳时付' ? '融资金额/本期确认应付金额' : '折价率 = 标的资产对应融资金额总和 / 转让价值金额总和', // 不输入则系统默认为95%
      attrs: {
        placeholder: '请输入',
        sum: true,
        maxlength: 7,
        type: 'BL',
        disabled: true
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: amountInput,
      on: {
        input: (data) => {
          if (parseFloat(data) >= 100) {
            // content.schemeInfoList[index].financeAmount = 9999.99
          }
        },
        change: () => {

        },
        blur: (data) => {
          if (content.schemeInfoList[index].totalPledgeRate - 100 > 0) {
            content.schemeInfoList[index].totalPledgeRate = ''
          }
        }

      }
      // isHidden: content.dictType !== '4',
      // rules: [{ required: true, message: '请输入综合质押率', trigger: ['change', 'blur'] }]
    },

    {
      label: '还款方式',
      showTip: content.dictType === '4' ? '请按内控需求设置还款方式' : '',
      prop: 'repayTypeCode',
      attrs: {
        placeholder: '请选择',
        schemeInfoList: content.schemeInfoList,
        formData: content.formData,
        options: getDictLists('REPAY_TYPE_STATUS'),
        selectedField: ['dictId', 'dictName'],
        ref: 'repayTypeCode'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: repaymentType,
      on: {
        repaymentType: (data) => {
          content.repaymentType(data)
        }
      },
      rules: [{ required: true, message: '请选择还款方式', trigger: ['change', 'blur'] }]
    },
    {
      label: content.dictType === '4' ? '还款详情' : '还款方式详情',
      prop: 'repayDetails',
      showBtn: true,
      attrs: {
        placeholder: placeholder1,
        type: 'textarea',
        iconfont: 'iconfont iconbianji3',
        rows: 4,
        maxlength: 2000,
        showTipText: '点击代入示例文案',
        textValue: '服务费按实际用款金额与时间每月支付，到期一次性偿还本金及剩余服务费，若公路一提前结算或借款人提前还款则允许提前扣划。'
      },
      rules: [{ required: true, message: '请输入还款方式详情', trigger: ['change', 'blur'] }],
      span: 24
    },
    {
      label: '融资用途',
      prop: 'financeUse',
      showBtn: true,
      attrs: {
        placeholder: placeholder2,
        type: 'textarea',
        rows: 4,
        restrict: false,
        iconfont: 'iconfont iconbianji3',
        maxlength: 2000,
        showTipText: '点击代入示例文案',
        textValue: '款项用于XXXXXXXXXX项目施工款以及材料款'
      },
      rules: [{ required: true, message: '请输入融资用途', trigger: ['change', 'blur'] }],
      span: 24
    },
    {
      label: '还款来源',
      prop: 'repaySource',
      showBtn: true,
      attrs: {
        placeholder: placeholder3,
        type: 'textarea',
        maxlength: 2000,
        iconfont: 'iconfont iconbianji3',
        rows: 4,
        showTipText: '点击代入示例文案',
        textValue: '融资项目XX月XX日申请编号FK20220245456456的应收进度款，公司经营资金。'
      },
      rules: [{ required: true, message: '请输入还款来源', trigger: ['change', 'blur'] }],
      span: 24
    }

  ]
}
export const repeatSchemeInfo = (content, index) => {
  const placeholder1 = '请输入：' + '\n' + '【示例：服务费按实际用款金额与时间每月支付，到期一次性偿还本金及剩余服务费，若公路一提前结算或借款人提前还款则允许提前扣划。】'
  const placeholder2 = '请输入：' + '\n' + '【示例：款项用于XXXXXXXXXX项目施工款以及材料款】'
  const placeholder3 = '请输入：' + '\n' + '【示例：融资项目XX月XX日申请编号FK20220245456456的应收进度款，公司经营资金。】'
  return [

    {
      label: '产品名称',
      prop: 'finaaceProductName',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请输入产品名称', trigger: ['blur'] }]
    },
    {
      label: '资金方',
      showTip: '到期一次性还本付息，可提前部分还款。不可循环额度',
      prop: 'capitalSideName', // capitalSideName
      attrs: {
        disabled: true
        // placeholder: '请选择',
        // options: content.providertData,
        // selectedField: ['keyId', 'capitalAndProductName']
      },
      // on: {
      //   change: (data, arr, index) => {
      //     const [obj] = content.providertData.filter(item => item.keyId === data)
      //     content.schemeInfoList[index].capitalSideName = obj ? obj.capitalSideName : ''
      //     content.schemeInfoList[index].surplusQuota = obj ? obj.surplusQuota : ''
      //     content.schemeInfoList[index].capitalSideProductId = obj ? obj.capitalSideProductId : ''
      //     // content.productRate(obj ? obj.capitalSideName : '', index)
      //     content.setproductRate(obj.productRate, index)
      //   }
      // },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      // tag: BaseSelect,
      rules: [{ required: true, message: '请选择资金方', trigger: 'change' }]
      // isHidden: content.dictType === '4'
    },
    {
      label: '存量余额(元)',
      prop: 'stockBalance',
      attrs: {
        placeholder: '请输入',
        sum: true,

        type: 'Y'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: amountInput,
      on: {

      },
      rules: [{ required: true, message: '请输入存量余额', trigger: ['change', 'blur'] }]
    },
    {
      label: '融资期限(月)',
      prop: 'financeTerm',
      attrs: {
        placeholder: '请输入',
        maxlength: 2
      },
      on: {
        input: (data) => {
          content.schemeInfoList[index].financeTerm = data.replace(/[^\d]|^[0]/g, '')
          content.schemeInfoList.forEach((ele, i) => { // 控制二级模式下融资期限统一
            if (i !== index) {
              content.$set(ele, 'financeTerm', content.schemeInfoList[index].financeTerm)
            }
          })
        },
        change: () => {
          const FinRepayPlanDTO = {
            advanceRepayTerm: '', // 提前还款期限
            chargeParty: '', // 收费主体
            finPlanId: '', // 融资方案ID
            repayAmount: '', // 还款金额
            repayRate: '', // 还款利率
            repayStatus: content.schemeInfoList[index].repayTypeCode ? content.schemeInfoList[index].repayTypeCode : '0', // 还款方式
            repayTime: '', // 还款时间(多个时间用逗号隔开)
            repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
          }

          const data = {
            data: FinRepayPlanDTO,
            key: index
          }
          content.repaymentType(data)
        }
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, validator: financeTermValidator, trigger: ['change', 'blur'] }]
    },
    {
      label: '综合融资利率(%)',
      prop: 'financeRate',
      attrs: {
        placeholder: '请输入',
        maxLength: 5,

        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [
        { required: true, validator: checkFinanceRate, trigger: ['change', 'blur'] }
      ],
      tag: amountInput,
      on: {
        blur: (data) => {
          content.schemeInfoList[index].financeRate = content.schemeInfoList[index].financeRate * 1
          if (content.schemeInfoList[index].financeRate - 100 > 0) {
            content.schemeInfoList[index].financeRate = ''
            content.schemeInfoList[index].serviceRateStr = ''
          } else {
            if (content.schemeInfoList[index].financeRate && content.schemeInfoList[index].costPrice) {
              if (content.schemeInfoList[index].financeRate * 1 - content.schemeInfoList[index].costPrice * 1 < 0) {
                content.warning('综合融资利率必须大于资方成本价格')
                content.schemeInfoList[index].serviceRateStr = ''
                content.schemeInfoList[index].financeRate = ''
              } else {
                content.schemeInfoList[index].serviceRateStr = (content.schemeInfoList[index].financeRate * 1000 - content.schemeInfoList[index].costPrice * 1000) / 1000
                content.schemeInfoList[index].finServiceCollectInfo = []
              }
            }
          }
        }
      }
    },
    {
      label: '资方成本价格(%)',
      prop: 'costPrice',
      attrs: {
        placeholder: '请输入',
        maxLength: 5,
        disabled: true,
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [
        { required: true, validator: checkCostPrice, trigger: ['change', 'blur'] }
      ],
      tag: amountInput,
      on: {
        blur: (data) => {
          // content.schemeInfoList[index].costPrice = content.schemeInfoList[index].costPrice * 1
          if (content.schemeInfoList[index].costPrice - 100 > 0) {
            content.schemeInfoList[index].costPrice = ''
            content.schemeInfoList[index].serviceRateStr = ''
          } else {
            if (content.schemeInfoList[index].financeRate && content.schemeInfoList[index].costPrice) {
              if (content.schemeInfoList[index].financeRate * 1 - content.schemeInfoList[index].costPrice * 1 < 0) {
                content.warning('综合融资利率必须大于资方成本价格')
                content.schemeInfoList[index].serviceRateStr = ''
                content.schemeInfoList[index].costPrice = ''
              } else {
                content.schemeInfoList[index].serviceRateStr = (content.schemeInfoList[index].financeRate * 1000 - content.schemeInfoList[index].costPrice * 1000) / 1000
                content.schemeInfoList[index].finServiceCollectInfo = []
              }
            }
          }
        }
      }
    },

    {
      label: '融资服务收费主体',
      prop: 'loanAssistanceOrg',
      attrs: {
        placeholder: '请选择',
        type: 'textarea',
        maxlength: '200',
        rows: 4,
        options: content.mainPartData,
        selectedField: ['enterpriseName', 'enterpriseName']
      },
      on: {
        change: (data, arr, index) => {
          content.mainPartData.forEach(ele => {
            if (ele.enterpriseName === content.schemeInfoList[index].loanAssistanceOrg) {
              content.schemeInfoList[index].loanAssistanceOrgId = ele.keyId
            }
          })
          content.schemeInfoList.forEach((ele, i) => { // 控制二级模式下签约主体统一
            if (i !== index) {
              content.$set(ele, 'loanAssistanceOrg', content.schemeInfoList[index].loanAssistanceOrg)
              content.$set(ele, 'loanAssistanceOrgId', content.schemeInfoList[index].loanAssistanceOrgId)
            }
          })
        }
      },
      rules: [{ required: true, message: '请选择融资服务收费主体', trigger: ['blur', 'change'] }],
      tag: BaseSelect,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '服务费率(%)',
      prop: 'serviceRateStr',
      attrs: {
        placeholder: '请输入',
        disabled: true,
        // disabled: content.schemeInfoList[index].serviceRateState,
        showBtn: true,
        serviceInput: true,
        schemeInfoList: content.schemeInfoList,
        payeeData: content.payeeData
      },
      on: {
        serviceTariffing: (data) => {
          content.serviceTariffing(data)
        },
        blur: (data) => {
          console.log(content.schemeInfoList[index].serviceRateStr, 'data')
          if (content.schemeInfoList[index].serviceRateStr === '0.0') {
            content.schemeInfoList[index].serviceRateStr = ''
          }
          if (content.schemeInfoList[index].serviceRateStr - 100 > 0) {
            content.schemeInfoList[index].serviceRateStr = ''
          }
        },
        input: (data) => {
          if (!content.schemeInfoList[index].serviceRateState) {
            // data = data.replace(/^[0]+[0-9]*$/gi, '')
            if (parseFloat(data) === 100) {
              content.schemeInfoList[index].serviceRateStr = '10'
            } else {
              content.schemeInfoList[index].serviceRateStr = data
            }
            if (content.schemeInfoList[index].serviceRateStr.indexOf('0.00') !== -1) {
              content.schemeInfoList[index].serviceRateStr = ''
            }
          }
        }
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: financingInput,
      rules: [{ required: true, message: '请输入服务费率', trigger: ['change', 'blur'] }]
    },

    {
      label: '综合质押率(%)',
      prop: 'totalPledgeRate',
      attrs: {
        placeholder: '请输入',
        sum: true,
        maxlength: 7,
        type: 'BL'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: amountInput,
      on: {
        input: (data) => {
          if (parseFloat(data) >= 100) {
            // content.schemeInfoList[index].financeAmount = 9999.99
          }
        },
        change: () => {

        },
        blur: (data) => {
          if (content.schemeInfoList[index].totalPledgeRate - 100 > 0) {
            content.schemeInfoList[index].totalPledgeRate = ''
          }
        }

      },
      // isHidden: content.dictType !== '4',
      rules: [{ required: true, message: '请输入综合质押率', trigger: ['change', 'blur'] }]
    },

    {
      label: '还款方式',
      prop: 'repayTypeCode',
      showTip: '请按内控需求设置还款方式',
      attrs: {
        placeholder: '请选择',
        schemeInfoList: content.schemeInfoList,
        formData: content.formData,
        options: getDictLists('REPAY_TYPE_STATUS'),
        selectedField: ['dictId', 'dictName'],
        ref: 'repayTypeCode'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: repaymentType,
      on: {
        repaymentType: (data) => {
          content.repaymentType(data)
        }
      },

      rules: [{ required: true, message: '请选择还款方式', trigger: ['change', 'blur'] }]
    },
    {
      label: content.dictType === '4' ? '还款详情' : '还款方式详情',
      prop: 'repayDetails',
      showBtn: true,
      attrs: {
        placeholder: placeholder1,
        type: 'textarea',
        iconfont: 'iconfont iconbianji3',
        rows: 4,
        maxlength: 2000,
        showTipText: '点击代入示例文案',
        textValue: '服务费按实际用款金额与时间每月支付，到期一次性偿还本金及剩余服务费，若公路一提前结算或借款人提前还款则允许提前扣划。'
      },
      rules: [{ required: true, message: '请输入还款方式详情', trigger: ['change', 'blur'] }],
      span: 24
    },
    {
      label: '融资用途',
      prop: 'financeUse',
      showBtn: true,
      attrs: {
        placeholder: placeholder2,
        type: 'textarea',
        rows: 4,
        restrict: false,
        iconfont: 'iconfont iconbianji3',
        maxlength: 2000,
        showTipText: '点击代入示例文案',
        textValue: '款项用于XXXXXXXXXX项目施工款以及材料款'
      },
      rules: [{ required: true, message: '请输入融资用途', trigger: ['change', 'blur'] }],
      span: 24
    },
    {
      label: '还款来源',
      prop: 'repaySource',
      showBtn: true,
      attrs: {
        placeholder: placeholder3,
        type: 'textarea',
        maxlength: 2000,
        iconfont: 'iconfont iconbianji3',
        rows: 4,
        showTipText: '点击代入示例文案',
        textValue: '融资项目XX月XX日申请编号FK20220245456456的应收进度款，公司经营资金。'
      },
      rules: [{ required: true, message: '请输入还款来源', trigger: ['change', 'blur'] }],
      span: 24
    }
  ]
}
// 建行融资方案信息
export const newSchemeInfo = (content, index) => {
  console.log(content.schemeInfoList, 'schemeInfoList')
  // const span = 5
  return [
    {
      label: content.schemeInfoList.length > 1 ? '借款人' + (index + 1) : '借款人',
      prop: 'mainBorrowerId',
      rules: [{ required: true, message: '请选择借款人', trigger: ['change', 'blur'] }],
      attrs: {
        placeholder: '请选择',
        options: content.personsDatas,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true

      },
      on: {
        change: (data, arr, index) => {
          content.personsChange(data, arr, index)
        }
      },
      tag: NewBaseSelect,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      rules: [{ required: true, message: '请输入统一社会信用代码', trigger: ['change', 'blur'] }],
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
    },
    {
      label: '共借人',
      prop: 'coBorrowerId',
      attrs: {
        placeholder: '请选择',
        options: content.schemeInfoList[index].personsData,
        selectedField: ['personKeyId', 'personnerName'],
        filterable: true
      },
      isHidden: content.dictType === '3' || content.dictType === '4',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      on: {
        change: (data, arr, index) => {
          content.personnerChange(data, arr, index)
        }
      },
      tag: BaseSelect,
      rules: [{ required: true, message: '请选择共借人', trigger: ['change', 'blur'] }]
    },
    {
      label: '身份证号',
      prop: 'idCard',
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      isHidden: content.dictType === '3' || content.dictType === '4',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请在供应商管理功能中预先维护人员身份证号码', trigger: ['change', 'blur'] }]
    },
    {
      label: '融资金额(元)',
      prop: 'financeAmount',
      attrs: {
        placeholder: '请输入',
        sum: true,

        type: 'Y'
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: amountInput,
      on: {
        input: (data) => {
          if (parseFloat(data) >= 100000000) {
            content.schemeInfoList[index].financeAmount = '99999999.99'
          }
          // content.schemeInfoList[index].financeAmount = data.toString().replace(/^[0]+[0-9]*$/gi, '')
          // if (content.schemeInfoList[index].financeAmount.indexOf('0.') !== -1 && content.schemeInfoList[index].financeAmount.substr(0, 1) === '0') {
          //   content.schemeInfoList[index].financeAmount = ''
          // }
        },
        change: () => {
          const FinRepayPlanDTO = {
            advanceRepayTerm: '', // 提前还款期限
            chargeParty: '', // 收费主体
            finPlanId: '', // 融资方案ID
            repayAmount: '', // 还款金额
            repayRate: '', // 还款利率
            repayStatus: content.schemeInfoList[index].repayTypeCode ? content.schemeInfoList[index].repayTypeCode : '0', // 还款方式
            repayTime: '', // 还款时间(多个时间用逗号隔开)
            repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
          }

          const data = {
            data: FinRepayPlanDTO,
            key: index
          }
          content.repaymentType(data)
          if (content.dictType === '4') {
            // 中小担 重置股东会决议
            content.clearShareholderMeeting()
          }
        }
        // blur: (data) => {
        //   const num = data.target.value.toString().replace(/^[0]+[0-9]*$/gi, '')
        //   if (parseFloat(num)) {
        //     content.schemeInfoList[index].financeAmount = num
        //   } else {
        //     content.schemeInfoList[index].financeAmount = ''
        //   }
        // }
      },
      rules: [{ required: true, validator: financeAmountValidator, trigger: ['change', 'blur'] }]
    }

  ]
}
// 个人担保
export const personalForm = (content) => {
  return [

    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证号',
      prop: 'idCard'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '家庭地址',
      prop: 'address',
      formatter: row => {
        return row.address ? row.address : '--'
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 个人担保财富资金版本
export const newpersonalForm = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证号',
      prop: 'idCard'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '家庭地址',
      prop: 'address',
      isHidden: content.dictType === '1',
      formatter: row => {
        return row.address ? row.address : '--'
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 个人担保子表格
export const personalFormChildren = () => {
  return [
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '家庭地址',
      prop: 'address',
      formatter: row => {
        return row.address ? row.address : '--'
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 企业担保
export const enterpriseForm = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'companyName'
    },
    {
      label: '统一社会信用代码',
      prop: 'nsrsbh'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 选择账户信息
export const selectAccountColumns = () => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '80px'
    },
    {
      label: '户名',
      prop: 'account',
      align: 'left'
    },
    {
      label: '开户行',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '账号',
      prop: 'accountNo',
      align: 'left'
    },
    {
      label: '账户类型',
      prop: 'accountType',
      formatter: row => {
        const [obj] = getDictLists('SUPPLIER_BANK_TYPE').filter(item => item.dictId === row.accountType.toString())
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 新增账户信息
export const addAccountColumns = () => {
  return [
    {
      label: '户名',
      prop: 'account'
    },
    {
      label: '开户行',
      prop: 'bankName'
    },
    {
      label: '账号',
      prop: 'accountNo'
    },
    {
      label: '账户类型',
      prop: 'accountType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 出质人信息
export const pledgorForm = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '合同名称',
      prop: 'finContractName',
      HiddenOverflow: true
    },
    {
      label: '合同编号',
      prop: 'finContractCode'
    },
    {
      label: '实际合同名称',
      prop: 'finContractNames',
      width: '300px'
    },
    {
      label: '项目债务人',
      prop: 'projectDebtor'
    },
    {
      label: '合同对价',
      prop: 'finContractAmount'
    },
    {
      label: '质押价值',
      prop: 'pledgeValue'
    },
    {
      label: '对应融资额',
      prop: 'relLoanAmount'
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 监管账户
export const supervise = (content) => {
  const span = 6
  return [
    {
      // label: content.dictType === '4' ? '户名' : '监管账户',
      label: '监管账户',
      prop: 'account',
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        accountTypes: 0,
        gysId: content.gysId,
        rowData: content.accountData,
        disabled: true,
        hideBtn: content.isDetail
      },
      tag: SelectAccount,
      span,
      on: {
        selectAccount (data) {
          content.addSelectAccount(data)
        },
        clearFormValidate (data) {
          content.clear()
        }
      },
      rules: [{ required: true, message: '请输入监管账户', trigger: ['blur'] }]
    },
    {
      label: '开户行',
      prop: 'bankName',
      attrs: {
        placeholder: '请输入',
        disabled: true,
        maxlength: 50
      },
      disabled: true,
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.accountData.bankName = data.replace(/&quot;|&lt;|&gt;|[\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      },
      span,
      rules: [{ required: true, message: '请输入开户行', trigger: ['change'] }]
    },
    {
      label: '账号',
      prop: 'accountNo',
      attrs: {
        placeholder: '请输入',
        disabled: true,
        maxlength: 30

      },

      on: {
        input: data => {
          // content.accountData.accountNo = data.replace(/^[0]+[0-9]*$/gi, '').replace(/[^\d]/g, '')
        }
      },
      span,
      rules: [{ required: true, message: '请输入账号', trigger: ['change'] }]
    },
    {
      label: '备注',
      prop: 'remark',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 2000,
        rows: 4
      },
      on: {
        input: data => {
          // content.accountData.accountNo = data.replace(/^[0]+[0-9]*$/gi, '').replace(/[^\d]/g, '')
        }
      },
      // isHidden: content.dictType === '1' || content.dictType === '4',
      span: 24,
      rules: [{ required: false, message: '请输入账号', trigger: ['change'] }]
    }
  ]
}
// 保理结算账户
export const settlementAccount = (content, index) => {
  const span = 6
  const getLabel = () => {
    if (content.finRepayBankAccountInfoDTOs.length > 1) {
      if (content.schemeInfoList[index].mainBorrower) {
        return content.schemeInfoList[index].mainBorrower
      } else {
        return '放款账户' + (index + 1)
      }
    } else {
      return '放款账户'
    }
  }
  return [
    {
      // label: content.finRepayBankAccountInfoDTOs.length > 1 ? '放款账户' + (index + 1) : '放款账户',
      label: getLabel(),
      prop: 'account',
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        gysId: content.gysId,
        rowData: content.finRepayBankAccountInfoDTOs,
        mainBorrowerData: content.schemeInfoList,
        disabled: true,
        index: index,
        hideBtn: content.isDetail
      },
      tag: SelectAccount,
      span,
      on: {
        selectAccount (data) {
          content.add(data, index)
        },
        clearFormValidate (data, index) {
          content.clear()
        }
      },
      rules: [{ required: content.dictType === '3', message: '请输入监管账户', trigger: ['blur'] }]
    },
    {
      label: '开户行',
      prop: 'bankName',
      attrs: {
        placeholder: '请输入',
        maxlength: 50,
        disabled: true
      },
      span,
      rules: [{ required: content.dictType === '3', message: '请输入开户行', trigger: ['blur'] }]
    },
    {
      label: '账号',
      prop: 'accountNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 30,
        disabled: true
      },
      on: {
        input: data => {
          // content.accountData.accountNo = data.replace(/^[0]+[0-9]*$/gi, '').replace(/[^\d]/g, '')
        }
      },
      span,
      rules: [{ required: content.dictType === '3', message: '请输入账号', trigger: ['blur'] }]
    }
  ]
}
// 保理相关信息
export const factoringRelated = (content) => {
  // const span = 5
  return [
    {
      label: '保理融资利息率(年化：%)',
      prop: 'capitalCost',
      attrs: {
        placeholder: '请输入',
        maxlength: 5,
        disabled: false
      },
      tag: ProportionInput,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, validator: checkCapitalCost, trigger: ['change', 'blur'] }]
      // formatter: row => {
      //   // return (row.capitalCost === '0' || row.capitalCost === 0) ? '' : row.capitalCost
      // }
    },
    {
      label: '保理融资服务费率(年化：%)',
      prop: 'serviceRate',
      attrs: {
        placeholder: '请输入',
        maxlength: 5,
        disabled: false
      },
      tag: ProportionInput,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, validator: checkServiceRate, trigger: ['change', 'blur'] }]
      // formatter: row => {
      //   // return (row.serviceRate === '0' || row.serviceRate === 0) ? '' : row.serviceRate
      // }
    },
    {
      label: '综合融资利率(年化：%)',
      prop: 'yearRate',
      attrs: {
        placeholder: '请输入',
        maxlength: 5,
        disabled: true
      },
      tag: ProportionInput,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, validator: checkYearRate, trigger: ['change', 'blur'] }],
      on: {
        input: (data) => {
          content.serviceData.yearRate = data.replace(/[^\d]/g, '')
        }
      },
      formatter: row => {
        // return (row.yearRate === '0' || row.yearRate === 0) ? '' : row.yearRate
      }
    },
    {
      label: '保理融资期限(天)',
      prop: 'financeTerm',
      attrs: {
        placeholder: '请输入',
        maxlength: 5,
        disabled: false
      },
      on: {
        input: (data) => {
          content.serviceData.financeTerm = data.replace(/[^\d]/g, '')
        }
      },
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请输入保理融资期限', trigger: ['change', 'blur'] }],
      formatter: row => {
        // return (row.financeTerm === '0' || row.financeTerm === 0) ? '' : row.financeTerm
      }
    },
    {
      label: '保理融资金额(元)',
      showTip: content.dictType === '3' ? '融资本金' : '合同转让价值总额 * 折价率',
      prop: 'factoryAmount',
      attrs: {
        placeholder: '请输入',
        maxlength: 16,
        disabled: true
      },
      tag: amountInput,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请输入回购保理融资金额', trigger: ['change', 'blur'] }]
      // formatter: row => {
      //   return (row.factoryAmount === '0' || row.factoryAmount === 0) ? '' : row.factoryAmount
      // }
    }
  ]
}
// 转让人信息
export const alienatorForm = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '合同名称',
      prop: 'finContractName',
      HiddenOverflow: true
    },
    {
      label: '合同编号',
      prop: 'finContractCode',
      HiddenOverflow: true
    },
    {
      label: '实际合同名称',
      prop: 'finContractNames',
      HiddenOverflow: true,
      width: '300px'
    },
    {
      label: '项目债务人',
      prop: 'projectDebtor',
      HiddenOverflow: true
    },
    {
      label: '合同对价',
      prop: 'finContractAmount',
      HiddenOverflow: true
    },
    {
      label: '转让价值',
      prop: 'pledgeValue',
      HiddenOverflow: true
    },
    {
      label: '对应融资额',
      prop: 'relLoanAmount',
      HiddenOverflow: true
    },
    {
      label: '质押率(%)',
      prop: 'pledgeRate',
      isHidden: content.dictType !== '4',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 补充合同信息
export const contractInfo = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '合同编号',
      prop: 'finContractCode'
    },
    {
      label: '合同名称',
      prop: 'finContractName'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 抵质押物
export const pledgeConfig = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '抵质押物',
      prop: 'pawnName'
    },
    {
      label: '价值明细(元)',
      prop: 'pawnValue'
    },
    {
      label: '操作',
      width: '80px',
      prop: 'action'
    }
  ]
}
// 已控制关联资金路径的其他账户
export const accountDataConfig = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '结算账户',
      prop: 'factoringAccountName'
    },
    {
      label: '开户行',
      prop: 'factoringAccountBank'
    },
    {
      label: '账号',
      prop: 'factoringAccountNumber'
    },
    {
      label: '操作',
      width: '80px',
      prop: 'action'
    }
  ]
}
// 还款计划表
export const repaymentPlanConfig = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '还款日期',
      prop: 'preRepayDate',
      formatter: row => {
        if (row.isDate) {
          return row.preRepayDate
        } else {
          return formatDate(row.preRepayDate, 'YY-MM-DD')
        }
      },
      HiddenOverflow: true,
      width: '230px'
    },
    {
      label: '还款金额(元)',
      prop: 'preRepayAmount',
      width: '210px',
      formatter: row => {
        return row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-'
      }
    },
    {
      label: '还款合同',
      prop: 'contractRepaymentArr',
      HiddenOverflow: true
    },
    {
      label: '合同编号',
      prop: 'contractCode',
      HiddenOverflow: true
    },
    {
      label: '还款条件',
      prop: 'repayCondition',
      HiddenOverflow: true
    },
    {
      label: '备注',
      prop: 'remark',
      HiddenOverflow: true
    },
    {
      label: '操作',
      prop: 'action',
      width: 80
    }
  ]
}
// 其他备注
export const otherNote = (content) => {
  const span = 12
  return [
    {
      label: '签约方式',
      prop: 'signType',
      attrs: {
        placeholder: '请选择',
        options: [{ label: '线上签约', value: '0' }, { label: '双人面签', value: '1' }, { label: '其他', value: '2' }],
        otherSignRemark: content.formData.otherSignRemark
      },
      span,
      tag: SigningMethod,
      rules: [{ required: true, message: '请选择签约方式', trigger: 'change' }],
      on: {
        changeOtherData (data) {
          content.formData.otherSignRemark = data
        }
      }
    },
    // {
    //   label: '盖章方式',
    //   prop: 'suppliersSealType',
    //   attrs: {
    //     placeholder: '请选择',
    //     options: getDictLists('SUPPLIERS_SEAL_TYPE'),
    //     selectedField: ['dictId', 'dictName'],
    //     disabled: true
    //   },
    //   span: 6,
    //   tag: BaseSelect,
    //   rules: [{ required: true, message: '请选择盖章方式', trigger: 'change' }]
    // },
    {
      label: '预计签署时间',
      prop: 'signTime',
      attrs: {
        placeholder: '请选择',
        type: 'date',
        'value-format': 'timestamp'
      },
      span: 6,
      tag: 'el-date-picker',
      rules: [{ required: false, message: '请选择预计签署时间', trigger: 'change' }]
    },
    {
      label: '备注',
      prop: 'remark',
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: 1000,
        rows: 4
      },
      on: {
        input: data => {
          // content.accountData.accountNo = data.replace(/^[0]+[0-9]*$/gi, '').replace(/[^\d]/g, '')
        }
      },
      isHidden: content.dictType === '1' || content.dictType === '4',
      span: 24,
      rules: [{ required: false, message: '请输入账号', trigger: ['change', 'blur'] }]
    }
    // {
    //   label: '预计放款时间：',
    //   prop: 'expectLoanDate',
    //   attrs: {
    //     placeholder: '请选择',
    //     valueFormat: 'yyyy-MM-dd'
    //   },
    //   span,
    //   tag: 'el-date-picker',
    //   rules: [{ required: true, message: '请选择预计放款时间', trigger: 'change' }]
    // }
  ]
}
// 文件生成
export const otherFile = (content) => {
  const span = 12
  return [
    {
      label: '文件名称',
      prop: 'fileName',
      attrs: {
        placeholder: '请生成或上传文件',
        generatefileId: content.generatefileId,
        hideGenerator: content.isDetail
      },
      span,
      tag: fileUpdate,
      rules: [{ required: true, message: '请生成或上传文件', trigger: 'change' }],
      on: {
        generate: (data) => {
          content.saveData('generate')
        },
        upload: (data) => {
          content.generatefileId = data.fileId
        },
        preview: (data) => {
          content.previewShow()
        },
        delFile: () => {
          content.delFile()
        }
      }
    }

  ]
}
// 评审会问题
export const problemConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '问题描述',
      prop: 'description',
      minWidth: '150px',
      HiddenOverflow: true
    },
    {
      label: '落实情况',
      prop: 'situation',
      minWidth: '150px',
      HiddenOverflow: true
    },

    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
