<template>
<div >
       <el-radio-group v-bind="$attrs" v-on="$listeners" >
      <el-radio
        v-for="(item, index) of options"
        :label="item.value"
        :key="index"
        >{{ item.label }}</el-radio
      >
    </el-radio-group>

    <span v-show="$attrs.value === '2'" class="pos">
       <div class="red"  >*</div>
         <el-input
            v-show="$attrs.value === '2'"
            class="othersInput"
            placeholder="请输入"
             maxlength="100"
            v-model="otherData"
          ></el-input>
    </span>

</div>
</template>

<script>

export default {
  components: {},
  props: { options: Array },
  data () {
    return {

    }
  },
  // 计算属性 类似于data概念
  computed: {
    otherData: {
      get () {
        return this.$attrs.otherSignRemark
      },
      set (val) {
        this.$emit('changeOtherData', val) // 修改其他备注
      }
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.pos{
  position: relative;
}
.red{
  position: absolute;
  top: -20px;
  left: 0;
  color: #F56C6C;
}
.othersInput {
  max-width:300px;
  margin-left: 10px;
}
</style>
